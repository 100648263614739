import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	mainLogo: {
		height: '100%',
		[theme.breakpoints.down('md')]: {
			height: '65%',
			paddingRight: theme.spacing(4),
		},
	},
}));

export const Header = () => {
	const classes = useStyles();
	return <img className={classes.mainLogo} src="/assets/icons/RainbowRollsLogoMain.svg" />;
};
