import { RouterStore } from 'mobx-router';
import { createContext } from 'react';
import ContractStore from './stores/ContractStore';
import UiStore from './stores/UiStore';
import OnboardStore from './stores/OnboardStore';
import NetworkConfig from 'config/types/network-config.interface';
import { Network } from 'config/enums/network.enum';

export class RootStore {
	public router: RouterStore<RootStore>;
	public wallet: OnboardStore;
	public contracts: ContractStore;
	public uiState: UiStore;

	private defaultConfig: NetworkConfig = {
		network: Network.Ethereum,
		id: 1,
	};

	constructor() {
		this.router = new RouterStore<RootStore>(this);
		this.wallet = new OnboardStore(this, this.defaultConfig);
		this.contracts = new ContractStore(this);
		this.uiState = new UiStore(this);
	}
}

const store = new RootStore();

export const StoreContext = createContext({} as RootStore);
export const StoreProvider = StoreContext.Provider;

export default store;
