import React from 'react';
import { Route } from 'mobx-router';
import { Landing } from '../components/Landing';
import { RootStore } from '../mobx/RootStore';
// TODO: add not found page
// import { NotFound } from '../components-v2/common/NotFound';

const routes = {
	home: new Route<RootStore>({
		path: '/',
		component: <Landing />,
	}),
};

export default routes;
