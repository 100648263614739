import {
	Backdrop,
	Grid,
	Modal,
	Fade,
	Paper,
	Typography,
	makeStyles,
	TextField,
	Button,
	Tooltip,
	Snackbar,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState, useContext } from 'react';
import { StoreContext } from 'mobx/RootStore';
import { StandardButton } from 'components/common/buttons';
import BigNumber from 'bignumber.js';
import { MAX_MINT_AMOUNT, MINT_DATE, RAINBOW_GRADIENT } from 'config/constants';
import { Alert, AlertColor } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		textAlign: 'center',
		minWidth: '20%',
	},
	titleText: {
		marginBottom: theme.spacing(1),
	},

	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rollButton: {
		filter: 'drop-shadow(0px 4px 4px #21458E) drop-shadow(3px 5px 10px rgba(33, 69, 142, 0.25))',
		marginTop: theme.spacing(4),
		width: '15rem',
		backgroundColor: 'white',
		color: theme.palette.text.primary,
		borderColor: theme.palette.text.primary,
		'&:hover': {
			opacity: 0.95,
			backgroundColor: 'white',
		},
		'&:disabled': {
			filter: 'drop-shadow(0px 4px 4px rgb(129, 154, 204)) drop-shadow(3px 5px 10px rgba(33, 69, 142, 0.25))',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '.75rem',
			width: '9rem',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	marginTop: {
		marginTop: theme.spacing(4),
	},
	rainbowButton: {
		background: RAINBOW_GRADIENT,
		color: 'white',
		'&:disabled': {
			opacity: '70%',
			color: '#e1dbdb',
		},
	},
	inputContainer: {
		maxWidth: '50%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	inputLabel: { textAlign: 'left', paddingLeft: '12px' },
}));

enum SaleStatus {
	Disabled = 'disabled',
	Premint = 'premint',
	Open = 'open',
}

const MintModal = observer(() => {
	const classes = useStyles();
	const store = useContext(StoreContext);
	const {
		wallet: { address },
		contracts: { mintedAmount, buy, presalePaused, salePaused, isWhitelisted },
	} = store;
	const userConnected = !!address;
	const mintsLeft = mintedAmount ? 10000 - mintedAmount : 10000;
	const maxMintAmount = MAX_MINT_AMOUNT > mintsLeft ? mintsLeft : MAX_MINT_AMOUNT;
	let saleStatus: SaleStatus = SaleStatus.Disabled;

	if (!salePaused) {
		saleStatus = SaleStatus.Open;
	} else if (!presalePaused) {
		saleStatus = SaleStatus.Premint;
	}

	const [open, setOpen] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [formError, setFormError] = useState(false);
	const [inputAmount, setInputAmount] = useState('0');
	const [snackMessage, setSnackMessage] = useState('');
	const [snackSeverity, setSnackSeverity] = useState('error' as AlertColor);

	const handleModalClick = () => {
		setOpen(!open);
		setInputAmount('0');
	};

	const handleInputAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const newVal =
			event.target.value.length > 0
				? event.target.value.slice(0, 1) === '0'
					? event.target.value.slice(1)
					: event.target.value
				: '0';
		const newChar = newVal.slice(-1);
		if (isNaN(parseInt(newVal)) || isNaN(parseInt(newChar))) {
			return;
		}
		const isValid = new BigNumber(newVal).lte(new BigNumber(maxMintAmount));
		setFormError(!isValid);
		setInputAmount(newVal);
	};

	const useMaxBalance = () => {
		setInputAmount(maxMintAmount.toString());
		setFormError(false);
	};

	const handleBuy = async (amount: number) => {
		const returnAmount = await buy(amount, saleStatus === SaleStatus.Premint);
		console.log('return info:', returnAmount);
		if (typeof returnAmount === 'string') {
			setSnackSeverity('error');
			setSnackMessage(`Error minting: ${returnAmount}`);
		} else {
			setSnackSeverity('success');
			setSnackMessage(`Successfully minted ${returnAmount} Rainbow Roll${returnAmount > 1 ? 's' : ''}!`);
		}
		setSnackbarOpen(true);
	};

	const mintButtonTooltip = () => {
		if (!userConnected) return 'Please connect wallet';
		if (saleStatus === SaleStatus.Disabled) return 'Minting is not currently enabled.';
		else return '';
	};

	const mintButtonTitle = () => {
		if (!userConnected) return 'Connect Wallet to Mint';
		if (saleStatus === SaleStatus.Disabled) return 'Minting Not Open';
		if (saleStatus === SaleStatus.Premint && !isWhitelisted) return 'Address Not Whitelisted';
		if (saleStatus === SaleStatus.Premint) return 'Premint';
		if (saleStatus === SaleStatus.Open) return 'ROLL WITH US...';
	};

	const handleClose = () => {
		setSnackbarOpen(false);
		setOpen(false);
	};

	const isDisabled = () => {
		if (saleStatus == SaleStatus.Disabled || !userConnected) {
			return true;
		}
		if (saleStatus === SaleStatus.Premint && !isWhitelisted) {
			return true;
		}
		return false;
	};

	return (
		<>
			<Grid container direction="row" justifyContent="flex-start">
				<Tooltip placement="bottom" title={mintButtonTooltip()}>
					<>
						<StandardButton
							disabled={isDisabled()}
							className={classes.rollButton}
							variant="outlined"
							onClick={() => handleModalClick()}
						>
							{mintButtonTitle()}
						</StandardButton>
					</>
				</Tooltip>
			</Grid>

			<Modal
				aria-labelledby="claim-modal"
				aria-describedby="Claim your rewards"
				open={open}
				onClose={handleClose}
				className={classes.modal}
			>
				<Paper className={classes.paper}>
					<Typography id="modalTitle" variant="subtitle1" className={classes.titleText}>
						MINT
					</Typography>
					<Typography className={classes.marginTop}>Mint Price: 0.1337 eth</Typography>
					<Typography className={classes.marginTop}>
						There are {`${store.contracts.mintedAmount}`} / 10,000 Rainbow Rolls minted!
					</Typography>
					<Grid className={`${classes.marginTop} ${classes.inputContainer}`} container direction="column">
						<Typography variant="caption" color="textPrimary" className={classes.inputLabel}>
							mint amount
						</Typography>
						<TextField
							id={`claim-amount`}
							variant="outlined"
							value={inputAmount}
							error={formError}
							size={window.innerWidth >= 960 ? 'medium' : 'small'}
							onChange={handleInputAmount}
							InputProps={{
								endAdornment:
									window.innerWidth >= 960
										? [
												<Button
													key="token-select-btn"
													size="small"
													variant="outlined"
													onClick={useMaxBalance}
												>
													max
												</Button>,
										  ]
										: [],
							}}
						/>
						<Button
							className={`${classes.marginTop} ${classes.rainbowButton}`}
							variant="contained"
							color="secondary"
							disabled={Number(inputAmount) === 0 || formError}
							onClick={async () => await handleBuy(Number(inputAmount))}
						>
							MINT!
						</Button>
					</Grid>
				</Paper>
			</Modal>
			{/* <Snackbar
				open={snackbarOpen}
				autoHideDuration={15000}
				onClose={handleSnackbarClose}
				key={snackMessage}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={() => handleSnackbarClose('', '')} severity={snackSeverity} sx={{ width: '100%' }}>
					{snackMessage}
				</Alert>
			</Snackbar> */}
		</>
	);
});

export default MintModal;
