import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { RAINBOW_GRADIENT } from 'config/constants';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			paddingTop: theme.spacing(24),
			paddingBottom: theme.spacing(24),
			backgroundColor: 'white',
			zIndex: 2,
			textAlign: 'center',
		},
		header: {
			paddingBottom: theme.spacing(12),
			fontSize: '4rem',
			[theme.breakpoints.down('lg')]: {
				fontSize: '3rem',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
			},
		},
		rainbowText: {
			backgroundImage: RAINBOW_GRADIENT,
			WebkitBackgroundClip: 'text',
			color: 'transparent',
		},
		roadmap: {
			maxWidth: '75%',
		},
	}),
);

const Roadmap = () => {
	const classes = useStyles();
	return (
		<Grid className={classes.mainContainer} container direction="column" alignItems="center">
			<Typography className={`${classes.header} ${classes.rainbowText}`} variant="h3" color="textPrimary">
				ROADMAP TO CLEAN YOUR...
			</Typography>
			<img className={classes.roadmap} src="assets/images/roadmap.png" />
		</Grid>
	);
};

export default Roadmap;
