import React, { useContext, useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { MINT_DATE, RAINBOW_GRADIENT } from 'config/constants';
import { StoreContext } from 'mobx/RootStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(8),
			marginLeft: theme.spacing(4),
			textAlign: 'center',
			height: '500px',
			color: theme.palette.text.primary,
			backgroundImage: 'url(assets/images/roll-paper.png)',
			backgroundSize: '100% 100%',
			width: '450px',
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(8),
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '300px',
			},
		},
		gitcoin: {
			height: '38px',
			[theme.breakpoints.down('md')]: {
				height: '24px',
			},
		},
		ripMedical: {
			width: '101px',
			[theme.breakpoints.down('md')]: {
				width: '65px',
			},
		},
		largeImage: {
			width: '101px',
			[theme.breakpoints.down('md')]: {
				height: '68px',
			},
		},
		rainbowText: {
			backgroundImage: RAINBOW_GRADIENT,
			WebkitBackgroundClip: 'text',
			color: 'transparent',
		},
		dateLabels: {
			marginTop: '-32px',
		},
		labelItem: {
			marginTop: 0,
			padding: theme.spacing(0, 0, 0, 4.5),
		},
	}),
);

const displayDateNumber = (number: number): string => {
	return `0${number.toString()}`.slice(-2);
};

const handleLabel = (num: number, baseString: string, func: React.Dispatch<React.SetStateAction<string>>): void => {
	if (num === 1) {
		func(`${baseString}` + ' ');
	} else {
		func(`${baseString}s`);
	}
};

export const TPPaper = observer(() => {
	const store = useContext(StoreContext);
	const classes = useStyles();

	const {
		contracts: { salePaused },
	} = store;

	const [displayTimer, setDisplayTimer] = useState('');
	const [dayLabel, setDayLabel] = useState('days');
	const [hourLabel, setHourLabel] = useState('hrs');
	const [minuteLabel, setMinuteLabel] = useState('mins');
	const [secondLabel, setSecondLabel] = useState('secs');

	const interval = setInterval(function () {
		const now = new Date();
		const distance = +MINT_DATE - +now;

		// Time calculations for days, hours, minutes and seconds
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		setDisplayTimer(
			displayDateNumber(days) +
				':' +
				displayDateNumber(hours) +
				':' +
				displayDateNumber(minutes) +
				':' +
				displayDateNumber(seconds),
		);

		handleLabel(days, 'day', setDayLabel);
		handleLabel(hours, 'hr', setHourLabel);
		handleLabel(minutes, 'min', setMinuteLabel);
		handleLabel(seconds, 'sec', setSecondLabel);

		if (distance < 0 && !salePaused) {
			clearInterval(interval);
			setDisplayTimer('');
		} else if (distance < 0 && salePaused) {
			setDisplayTimer('Loading the TP Dispenser ...');
		}
	}, 1000);

	return (
		<Grid className={classes.mainContainer} container direction="column" justifyContent="space-between">
			<Typography variant="h5">FRESH ROLLS!</Typography>
			<Typography variant="h3" className={classes.rainbowText}>
				MINT IS OPEN!
			</Typography>
			{/* <Grid className={classes.dateLabels} container direction="row" justifyContent="center">
				<Typography>{dayLabel}</Typography>
				<Typography className={classes.labelItem}>{hourLabel}</Typography>
				<Typography className={classes.labelItem}>{minuteLabel}</Typography>
				<Typography className={classes.labelItem}>{secondLabel}</Typography>
			</Grid> */}
			<Typography>Proudly Supporting:</Typography>
			<Grid container direction="row" justifyContent="space-evenly" alignItems="center">
				<Grid item xs={4}>
					<img className={classes.gitcoin} src="/assets/icons/gitcoin.svg" />
				</Grid>
				<Grid item xs={4}>
					<img className={classes.gitcoin} src="/assets/icons/giveth-full-logo.svg" />
				</Grid>
				<Grid item xs={4}>
					<img className={classes.ripMedical} src="assets/icons/RIPMedicalDebt_01.svg" />
				</Grid>
			</Grid>
			<Typography className={classes.rainbowText}>It feels good to give a sheet!</Typography>
		</Grid>
	);
});

export default TPPaper;
