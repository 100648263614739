import React, { useContext } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { StandardTypography } from 'components/common/typography';
import { StandardButton } from 'components/common/buttons';
import { DISCORD_LINK } from 'config/constants';
import { StoreContext } from 'mobx/RootStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			background: 'linear-gradient(1.94deg, #81D5F2 -6.55%, #00ADEE 52.45%);',
			marginBottom: '-20px',
			marginTop: '-20px',
			paddingBottom: theme.spacing(24),
			paddingTop: theme.spacing(24),
		},
		daoContainer: {
			height: '100%',
			paddingRight: theme.spacing(2),
			[theme.breakpoints.down('md')]: {
				textAlign: 'left',
			},
		},
		discordButton: {
			width: '300px',
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(4),
				fontSize: '.75rem',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		rollImage: {
			width: '586px',
			[theme.breakpoints.down('lg')]: {
				width: '460px',
			},
			[theme.breakpoints.down('md')]: {
				width: '280px',
				paddingTop: theme.spacing(4),
				paddingBottom: theme.spacing(8),
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		rollContainer: {
			marginTop: 'auto',
			marginBottom: 'auto',
			textAlign: 'right',
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
			},
		},
		responsiveH2Text: {
			paddingBottom: theme.spacing(6),
			[theme.breakpoints.down('lg')]: {
				fontSize: '3rem',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
				paddingBottom: theme.spacing(4),
			},
		},
		responsiveH3Text: {
			paddingBottom: theme.spacing(6),
			[theme.breakpoints.down('md')]: {
				fontSize: '1.5rem',
				paddingBottom: theme.spacing(4),
			},
		},
		responsiveH5Text: {
			paddingBottom: theme.spacing(4),
			[theme.breakpoints.down('md')]: {
				fontSize: '1rem',
				paddingBottom: theme.spacing(2),
			},
		},
	}),
);

export const Dao = observer(() => {
	const classes = useStyles();
	const store = useContext(StoreContext);
	const {
		uiState: { desktopView },
	} = store;

	return (
		<Grid className={classes.mainContainer} container direction="row" justifyContent="space-evenly">
			<Grid item md={4} xs={8}>
				<Grid className={classes.daoContainer} container direction="column" justifyContent="space-evenly">
					<Typography className={classes.responsiveH2Text} variant="h2" color="textSecondary">
						DEPARTMENT OF HYGIENE
					</Typography>
					{!desktopView ? <img className={classes.rollImage} src="assets/images/roll-26.png" /> : null}
					<Typography className={classes.responsiveH3Text} variant="h4" color="textPrimary">
						We got together, thought about how to craft NFTs for good, then, built them.
					</Typography>
					<StandardTypography className={classes.responsiveH5Text} variant="h5" color="textSecondary">
						We're gastrointestinally experienced people living in the on-chain DeFi + NFT + DAO world that
						we've memed ourselves into.
					</StandardTypography>
					<StandardTypography className={classes.responsiveH5Text} variant="h5" color="textSecondary">
						We're a DAO, which means that you can become one of us. Join up, have a seat on your porcelain
						throne, and get wiping. Get to know us...
					</StandardTypography>
					<StandardButton
						variant="outlined"
						className={classes.discordButton}
						onClick={() => {
							window.open(DISCORD_LINK);
						}}
					>
						OFFICIAL NFTP DAO DISCORD
					</StandardButton>
				</Grid>
			</Grid>
			{desktopView ? (
				<Grid className={classes.rollContainer} item md={4} xs={12}>
					<img className={classes.rollImage} src="assets/images/roll-26.png" />
				</Grid>
			) : null}
		</Grid>
	);
});

export default Dao;
