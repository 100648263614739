import React, { useContext, useState } from 'react';

import { StoreContext } from '../../mobx/RootStore';
import { observer } from 'mobx-react-lite';
import { shortenAddress } from '../../helpers/eth';
import { ConnectButton } from '../common/buttons';
import { createStyles, makeStyles, Snackbar, Theme, Button, Typography, Grid } from '@material-ui/core';
import { RAINBOW_GRADIENT } from 'config/constants';
import { Alert } from '@mui/material';

export const navHeight = 4;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rainbowBackground: {
			background: RAINBOW_GRADIENT,
			[theme.breakpoints.down('md')]: {
				fontSize: '.7rem',
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
		blockNumber: {
			paddingTop: theme.spacing(0.5),
			fontSize: '.6rem',
			color: 'rgba(57, 57, 57, 0.87)',
			[theme.breakpoints.down('md')]: {
				fontSize: '.5rem',
			},
		},
	}),
);

export const WalletWidget = observer(() => {
	const store = useContext(StoreContext);
	const classes = useStyles();

	const {
		wallet: { address, onboard },
		contracts: { currentBlock, refreshContracts },
	} = store;
	const userConnected = !!address;

	const [open, setOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleClose = (_: any, reason: any) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleConnect = async () => {
		await onboard.walletSelect();
		await onboard.walletCheck();
		await refreshContracts();
	};

	const handleDisconnect = async () => {
		await store.wallet.reset();
	};

	return (
		<>
			<Grid container direction="column" alignItems="flex-end">
				<ConnectButton
					className={classes.rainbowBackground}
					variant="outlined"
					onClick={async () => (userConnected ? await handleDisconnect() : await handleConnect())}
				>
					{userConnected ? shortenAddress(address).toUpperCase() : 'CONNECT WALLET'}
				</ConnectButton>
				{userConnected ? (
					<Typography className={classes.blockNumber}>Current Block Number: {currentBlock}</Typography>
				) : null}
			</Grid>
			<Snackbar
				open={open}
				autoHideDuration={1500}
				onClose={handleClose}
				key={errorMessage}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={() => handleClose('', '')} severity="error" sx={{ width: '100%' }}>
					{errorMessage}
				</Alert>
			</Snackbar>
		</>
	);
});

export default WalletWidget;
