import { createTheme } from '@material-ui/core';

const fontFamily = [
	'Fredoka One',
	'Arial',
	'sans-serif',
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"',
].join(',');

export const mainTheme = createTheme({
	typography: {
		fontFamily: fontFamily,
		button: {
			textTransform: 'none',
		},
	},
	shape: {
		borderRadius: 15,
	},

	palette: {
		type: 'light',
		primary: {
			main: '#81D5F2',
		},
		secondary: {
			main: '#00ADEE',
		},
		text: {
			primary: '#21458E',
			secondary: '#FFFFFF',
		},
		//@ts-ignore
		// custom: {
		// 	raisedPaper: 'rgba(32, 34, 49, 1) !important',
		// 	backgroundCircle: '#121A31',
		// },
	},
});
