import { RootStore } from 'mobx/RootStore';
import { Initialization, API, Wallet } from 'bnc-onboard/dist/src/interfaces';
import Onboard from 'bnc-onboard';
import { BLOCKNATIVE_API_KEY, INFURA_ID, RPC } from 'config/constants';
import { action, extendObservable } from 'mobx';
import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers';
import NetworkConfig from 'config/types/network-config.interface';
import { Wallets } from 'config/enums/wallets.enum';

export default class OnboardStore {
	private store: RootStore;
	public onboard: API;
	private wallet?: Wallet;
	public provider?: Web3Provider;
	public signer?: JsonRpcSigner;
	public address?: string;

	constructor(store: RootStore, config: NetworkConfig) {
		extendObservable(this, {
			wallet: this.wallet,
			provider: this.provider,
			address: this.address,
			signer: this.signer,
		});

		this.store = store;
		const initialization: Initialization = {
			dappId: BLOCKNATIVE_API_KEY,
			networkId: config.id,
			networkName: config.network,
			blockPollingInterval: 15000,
			darkMode: false,
			subscriptions: {
				address: this.addressListener,
				network: this.networkListener,
				wallet: this.walletListener,
			},
			walletSelect: {
				wallets: [
					{
						walletName: Wallets.MetaMask.toLowerCase(),
						preferred: true,
					},
					// {
					// 	walletName: 'walletConnect',
					// 	rpc: {
					// 		['1']: RPC,
					// 	},
					// 	preferred: true,
					// },
				],
			},
		};
		this.onboard = Onboard(initialization);
		this.init();
	}

	async init() {
		const previouslySelectedWallet = window.localStorage.getItem('selectedWallet');
		if (previouslySelectedWallet) {
			await this.onboard.walletSelect(previouslySelectedWallet);
			await this.store.contracts.refreshContracts();
		}
	}

	isMetamask() {
		return this.wallet !== undefined && this.wallet.type === 'injected';
	}

	async ready() {
		return this.onboard.walletCheck();
	}

	async reset() {
		this.onboard.walletReset();
		this.address = undefined;
		this.provider = undefined;
		this.signer = undefined;
	}

	async connect() {
		await this.onboard.walletSelect();
	}

	addressListener = action(async (address: string) => {
		this.address = address;
	});

	networkListener = action(async (network: number) => {
		// add a network update hook on the root store
	});

	walletListener = action(async (wallet: Wallet) => {
		// add a wallet update hook on the root store
		console.log('wallet listener wallet:', wallet);
		this.wallet = wallet;
		this.provider = this.getProvider(wallet.provider);
		if (!this.provider) return;
		this.signer = this.provider.getSigner(0);
		if (wallet.name) window.localStorage.setItem('selectedWallet', wallet.name);
	});

	private getProvider(provider: any): Web3Provider | undefined {
		if (!provider) return;
		return new Web3Provider(provider);
	}
}
