import { extendObservable, action } from 'mobx';
import { RootStore } from '../RootStore';
import MINT_ABI from '../../config/ABI/test_contract.json';
import { GAS_LIMIT, MINT_ADDRESS, MINT_PRICE } from 'config/constants';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

class ContractStore {
	private store: RootStore;
	private mintContract: string = MINT_ADDRESS;
	public mintedAmount: number | null;
	public currentBlock: number | null;
	public presalePaused: boolean;
	public salePaused: boolean;
	public isWhitelisted: boolean;

	constructor(store: RootStore) {
		this.store = store;
		this.mintedAmount = null;
		this.currentBlock = null;
		this.presalePaused = true;
		this.salePaused = true;
		this.isWhitelisted = false;

		extendObservable(this, {
			mintedAmount: this.mintedAmount,
			currentBlock: this.currentBlock,
			presalePaused: this.presalePaused,
			salePaused: this.salePaused,
			isWhitelisted: this.isWhitelisted,
		});
		this.init();
	}

	init = action(async (): Promise<void> => {
		await this.refreshContracts();
		setInterval(async () => {
			console.log('interval running:', Date.now());
			await this.refreshContracts();
		}, 13000);
	});

	refreshContracts = action(async () => {
		if (!this.store.wallet.provider) return;
		try {
			await Promise.all([
				this.setMintedAmount(),
				this.getCurrentBlock(),
				this.setSaleStatus(),
				this.setWhitelisted(),
			]);
		} catch (e) {
			console.log('error refreshing contract data:', e);
			return;
		}
	});

	setMintedAmount = action(async () => {
		console.log('setMintedAmountCheck:', this.store.wallet.provider, this.mintContract);
		if (!this.store.wallet.provider || this.mintContract === '') return;
		const mint = new ethers.Contract(this.mintContract, MINT_ABI, this.store.wallet.provider);
		this.mintedAmount = await mint.totalCount();
	});

	setSaleStatus = action(async () => {
		if (!this.store.wallet.provider || this.mintContract === '') return;
		const mint = new ethers.Contract(this.mintContract, MINT_ABI, this.store.wallet.provider);
		this.presalePaused = await mint.presalePaused();
		this.salePaused = await mint.salePaused();
	});

	setWhitelisted = action(async () => {
		if (!this.store.wallet.provider || this.mintContract === '') return;
		const mint = new ethers.Contract(this.mintContract, MINT_ABI, this.store.wallet.provider);
		this.isWhitelisted = await mint.whitelistEligible(this.store.wallet.address);
	});

	getCurrentBlock = action(async () => {
		if (!this.store.wallet.provider) return;
		this.currentBlock = await this.store.wallet.provider.getBlockNumber();
	});

	buy = async (amount: number, presale: boolean): Promise<number | string> => {
		const {
			wallet: { address, signer },
		} = this.store;
		if (!signer) {
			return 'Provider not initialized.';
		}
		if (this.mintContract === '') return 'Error: Contract address not defined.';
		try {
			const mint = new ethers.Contract(this.mintContract, MINT_ABI, signer);
			const mintWithSigner = mint.connect(signer);
			const mintCost = ethers.utils.formatUnits(MINT_PRICE);
			const totalCost = new BigNumber(amount).multipliedBy(mintCost).multipliedBy(1e18);
			const overrides = {
				value: totalCost.toString(),
				gasLimit: GAS_LIMIT,
			};
			console.log('mint info:', signer, mint, mintCost, totalCost, overrides);

			const buyReturn = await mintWithSigner.buy(address, amount, overrides);
			console.log('waiting on buy return:', buyReturn);
			await buyReturn.wait();
			await this.setMintedAmount();
			return amount;
		} catch (e: any) {
			console.log('error:', e);
			return e.message;
		}
	};
}

export default ContractStore;
