export const RAINBOW_GRADIENT =
	'linear-gradient(98.85deg, #FFF100 -6.86%, #EC1C24 8.62%, #EC1A2D 14.57%, #EC1345 25.29%, #EB086D 37.2%, #EB008B 45.53%, #E2078F 46.72%, #C91999 50.29%, #A236AA 52.68%, #6A5FC1 57.44%, #2492DF 61.01%, #00ADEE 63.39%, #00ADEB 66.97%, #00ACE0 69.35%, #00ABCE 71.73%, #00AAB5 74.11%, #00A895 76.49%, #00A66E 78.87%, #00A551 80.06%, #09A84E 81.26%, #22AF46 84.83%, #4ABB3A 88.4%, #81CB28 93.16%, #C7E012 97.93%, #FFF100 101.5%)';
export const COLLAPSE_WIDTH = 1263;

export const BLOCKNATIVE_API_KEY = '14df66fb-62ff-41ae-9082-f869a772fe80';
export const INFURA_ID = '53d7e10b0832408f801f8d89a410c74f';
export const RPC = 'https://mainnet.infura.io/v3/fc08bdc6770c47faab08b4055e1d5b7f';
export const GAS_LIMIT = 100000;

export const TWITTER_LINK = 'https://twitter.com/rainbow_rolls';
export const DISCORD_LINK = 'https://discord.gg/PRYcnbdwSx';
export const TELEGRAM_LINK = 'https://t.me/joinchat/zX7mPNZ3D_E1OGM5';

export const TESTNET_MINT_ADDRESS = '0x79500c3b89CFC6cd159EbEC49aB06339193a21Bf';
export const MINT_ADDRESS = '0xf5918382Dd20Ecba89747c50f80fB7f9f1e0524C';
export const MAX_MINT_AMOUNT = 20;
export const MINT_DATE = 1634070000000; //Oct 12, 4:20pm EDT
export const MINT_PRICE = '133700000000000000';

export const OPENSEA_LINK = 'https://opensea.io/collection/rainbow-rolls';
export const CONTRACT_LINK = 'https://etherscan.io/address/0xf5918382dd20ecba89747c50f80fb7f9f1e0524c';
export const TOKEN_LINK = 'https://etherscan.io/token/0xf5918382dd20ecba89747c50f80fb7f9f1e0524c';
