import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import Hero from './hero/Hero';
import Navbar from './Navbar/Navbar';
import Social from './social/Social';
import Why from './why/Why';
import Charities from './charities/Charities';
import Dao from './dao/Dao';
import Roadmap from './roadmap/Roadmap';
import Footer from './footer/Footer';
import { RAINBOW_GRADIENT } from 'config/constants';

const useStyles = makeStyles((theme) => ({
	spacer: {
		paddingTop: '14rem',
	},
	heroContainer: {},
	topRoll: {
		backgroundImage: 'url(assets/images/top-roll.svg)',
		height: '30px',
		zIndex: 2,
	},
	bottomRoll: {
		backgroundImage: 'url(assets/images/bottom-roll.svg)',
		height: '30px',
		zIndex: 2,
	},
	rainbowText: {
		backgroundImage: RAINBOW_GRADIENT,
		WebkitBackgroundClip: 'text',
		color: 'transparent',
		cursor: 'pointer',
	},
	bannerText: {
		maxWidth: '90%',
		textAlign: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}));

export const Landing = observer(() => {
	const classes = useStyles();
	return (
		<Grid container direction="column" className={classes.heroContainer}>
			{/* <Grid container direction="row" justifyContent="center">
				<Typography color="textPrimary" className={classes.bannerText}>
					Premint Launches at 4:20pm EST on Monday, October 11th.{' '}
					<span
						className={classes.rainbowText}
						onClick={() => window.open('https://twitter.com/rainbow_rolls/status/1446954215989907458?s=20')}
					>
						Click Here
					</span>{' '}
					for more details!
				</Typography>
			</Grid>
			<div className={classes.bottomRoll} /> */}
			<Navbar />
			<Hero />
			<div className={classes.topRoll} />
			<Social />
			<div className={classes.bottomRoll} />
			<Why />
			<div className={classes.topRoll} />
			<Charities />
			<div className={classes.bottomRoll} />
			<Dao />
			<div className={classes.topRoll} />
			<Roadmap />
			<div className={classes.bottomRoll} />
			<Footer />
		</Grid>
	);
});
